import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './App.css';

import AddCategory from './Dashboard/Addcategory';
import ViewCategory from './Dashboard/Viewcategory';
import AddProduct from './Dashboard/Addproduct';
import ViewProduct from './Dashboard/Viewproduct';
import UpdateProduct from './Dashboard/Updateproduct';
import AddBlogs from './Dashboard/Addblogs';
import ViewBlogs from './Dashboard/Viewblog';
import Addblogsmedia from './Dashboard/Addblogsmedia';
import ViewMediaBlogs from './Dashboard/Viewmediablog';
import AddCouponCode from './Dashboard/Addcoupon';
import ViewCoupon from './Dashboard/Viewcoupon';
import Neworder from './Dashboard/Neworder';
import OrderDetails from './Dashboard/Orderdetails';
import Home from './Dashboard/Home';
import Testimonial from './Dashboard/Addtestimonial'

import logo from './logo515.png';

function App() {
  return (

    
    <Router>
       <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}
            alt="Nirogi Dhara Logo"
            style={{ height: '70px' }} // Adjust the height as needed
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            
            <NavDropdown title="Category" id="categoryDropdown">
              <NavDropdown.Item as={Link} to="/add-category">Add Category</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/view-category">View Category</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Product" id="productDropdown">
              <NavDropdown.Item as={Link} to="/add-product">Add Product</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/view-product">View Product</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Coupon" id="couponDropdown">
              <NavDropdown.Item as={Link} to="/add-coupon">Add Coupon</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/view-coupon">View Coupon</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Order" id="orderDropdown">
              <NavDropdown.Item as={Link} to="/new-order">New Order</NavDropdown.Item>
             
            </NavDropdown>

            <NavDropdown title="Blog" id="blogDropdown">
              <NavDropdown.Item as={Link} to="/add-blog">Add ECOMM Blog</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/view-blog">View ECOMM Blog</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/add-media-blog">Add MEDIA Blog</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/view-media-blog">View MEDIA Blog</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Testimonial" id="testimonialDropdown">
              <NavDropdown.Item as={Link} to="/new-testimonial">New Testimonial</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/manage-testimonial">Manage Testimonial</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="Review" id="reviewDropdown">
              <NavDropdown.Item as={Link} to="/new-review">New Review</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/manage-review">Manage Review</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Form className="d-flex">
            <Form.Control
              type="search"
              placeholder="Search"
              className="me-2"
              aria-label="Search"
            />
            <Button variant="outline-success">Search</Button>
          </Form>
        </Navbar.Collapse>
      </Container>
    </Navbar>


      <Routes>
      
        <Route path="/" element={<Home/>} />
        <Route path="/add-category" element={<AddCategory />} />
        <Route path="/view-category" element={<ViewCategory />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/view-product" element={<ViewProduct />} />
        <Route path="/update-product/:productId" element={<UpdateProduct />} />
        <Route path="/add-blog" element={<AddBlogs/>} />
        <Route path="/add-media-blog" element={<Addblogsmedia/>} />
        <Route path="/view-blog" element={<ViewBlogs/>} />
        <Route path="/view-media-blog" element={<ViewMediaBlogs/>} />
        <Route path="/add-coupon" element={<AddCouponCode/>} />
        <Route path="/view-coupon" element={<ViewCoupon/>} />
        <Route path="/new-order" element={<Neworder/>} />
        <Route path="/orderdetails/:orderId" element={<OrderDetails/>} />
        <Route path="/new-testimonial" element={<Testimonial/>} />
      </Routes>


      <footer className="bg-red text-center py-3 mt-auto">
        <p className="mb-0">Developed by Multitech Bulls Software Technologies Pvt Ltd</p>
      </footer>


    </Router>

    

    
    
  );
}

export default App;
