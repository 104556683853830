import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Viewproduct.css'; // Import custom CSS

import config from '../config';

const ViewCoupon = () => {
  const [coupon, setcoupon] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchcoupon`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setcoupon(data);
        console.log("Hello "+coupon);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  

  

  const handleDeleteClick = async (productId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/deleteblogs/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) 
      {
       alert("Product has been deleted");
      } 
      else 
      {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleUpdateClick = async (productId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/deleteblogs/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) 
      {
       alert("Product has been deleted");
      } 
      else 
      {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <div className="container mt-5">
      <div className="heading-container">
        <h1>View Coupon Code</h1>
      </div>
      <div className="table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Coupon Code</th>
              <th>Coupon Code Qty</th>
              <th>Minimum Order Value</th>
              <th>Coupon Use</th>
              <th>Discount</th>
              <th>Expiry Code</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {coupon.map(coupon=> (
              <tr key={coupon.id}>
                <td>{coupon.id}</td>
                <td>{coupon.couponCode}</td>
                <td>{coupon.couponCodeqty}</td>
                <td>{coupon.minimumordervalue}</td>
                <td>{coupon.couponuse}</td>
                <td>{coupon.discount}</td>
                <td>{coupon.expiryDate}</td>
                <td>{coupon.status}</td>
               
                
                


                <td>

                <button
                    className="btn btn-warning"
                    onClick={() => handleUpdateClick(coupon.id)}
                  >
                    Update
                  </button>

                  <br>
                  </br>
                  <br>
                  </br>

                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteClick(coupon.id)}
                  >
                    Delete
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewCoupon;
