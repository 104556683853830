import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Viewproduct.css'; // Import custom CSS

import config from '../config';

const ViewProduct = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchproducts`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleUpdateClick = (productId) => {
    navigate(`/update-product/${productId}`);
  };

  

  const handleDeleteClick = async (productId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/deleteproduct/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) 
      {
       alert("Product has been deleted");
      } 
      else 
      {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <div className="container mt-5">
      <div className="heading-container">
        <h1>View Products</h1>
      </div>
      <div className="table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Category</th>
              <th>Product Name</th>
              <th>Description</th>
              <th>Information</th>
              <th>Price</th>
              <th>Overprice</th>
              <th>Facebook Link</th>
              <th>Instagram Link</th>
              <th>Youtube Link</th>
              <th>Last Product Sell</th>
              <th>Stock Status</th>
              <th>Features</th>
              <th>Images</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.category}</td>
                <td>{product.productName}</td>
                <td>{product.description}</td>
                <td>{product.information}</td>
                <td>{product.price}</td>
                <td>{product.overprice}</td>
                <td><a href={product.fbLink} target="_blank" rel="noopener noreferrer">{product.fbLink}</a></td>
                <td><a href={product.instaLink} target="_blank" rel="noopener noreferrer">{product.instaLink}</a></td>
                <td><a href={product.youtubeLink} target="_blank" rel="noopener noreferrer">{product.youtubeLink}</a></td>
                <td>{product.lastProductSell}</td>
                <td>{product.stockStatus}</td>
                <td>
                  <ul className="list-unstyled">
                    {JSON.parse(product.features).map((feature, index) => (
                      <li key={index}>
                       {feature}
                      </li>
                    ))}
                  </ul>
                </td>
                
                <td>
                  <ul className="list-unstyled">
                    {JSON.parse(product.images).map((image, index) => (
                      <li key={index}>
                        <img
                          src={`${config.API_BASE_URL}/images/${image}`} // Construct URL for each image
                          alt={`Product Image ${index}`}
                          style={{ maxWidth: '100px', height: 'auto' }} // Adjust styles as needed
                        />
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                  <button
                    className="btn btn-warning"
                    onClick={() => handleUpdateClick(product.id)}
                  >
                    Update
                  </button>
                    <br></br>
                    <br></br>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteClick(product.id)}
                  >
                    Delete
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewProduct;
