import React, { useState, useEffect } from 'react';
import './Addproduct.css';

import config from '../config';

function Addblogsmedia() {
 
  const [blogcontent, setBlogcontent] = useState('');
 
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  // Fetch categories from the server
 

  
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);

    // Generate image previews
    const newPreviews = files.map(file => URL.createObjectURL(file));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const handleImageRemove = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviews((prevPreviews) => {
      const updatedPreviews = prevPreviews.filter((_, i) => i !== index);
      URL.revokeObjectURL(prevPreviews[index]);
      return updatedPreviews;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    
    formData.append('blog',blogcontent);
    images.forEach((image) => {
      formData.append(`images`, image);
    });

    console.log('FormData:', formData);
    try {
      const response = await fetch(`${config.API_BASE_URL}/addmediablogs`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Clear the form fields after submission
      setBlogcontent('');
     
      alert('Blog added successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('Error adding product'+error);
    }
  };

  return (
    <div className="product-container">
      <div className="add-product-container">
        <h1 className="text-center mb-4">Add Media Blog</h1>
        <form onSubmit={handleSubmit}>
          
          <div className="form-group mb-3">
            <label htmlFor="description">Blog Content</label>
            <textarea
              className="form-control mt-2"
              id="description"
              placeholder="Enter product description"
              value={blogcontent}
              onChange={(e) => setBlogcontent(e.target.value)}
              required
            />
          </div>
         

          


          
         
       
         

         

          <div className="form-group mb-3">
            <label htmlFor="images">Upload Images</label>
            <input
              type="file"
              className="form-control mt-2"
              id="images"
              multiple
              onChange={handleImageChange}
            />
          </div>
          {imagePreviews.length > 0 && (
            <div className="image-previews mt-3">
              {imagePreviews.map((preview, index) => (
                <div key={index} className="image-preview-container">
                  <img
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className="img-thumbnail"
                  />
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => handleImageRemove(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}


          <button type="submit" className="btn btn-primary btn-block mt-3">
            Save Blog
          </button>
        </form>
      </div>
    </div>
  );
}

export default Addblogsmedia;
