import React, { useState} from 'react';
import './Addproduct.css';

import config from '../config';

function AddCouponCode() {
 
  const [couponCode, setCouponcode] = useState('');
  const [couponCodeqty, setCouponcodeqty] = useState('');
  const [miniumordervalue, setMinimumordervalue] = useState('');
  const [discountvalue, setdiscountvalue] = useState('');
  const [expiryDate, setExpiryDate] = useState('');

  // Fetch categories from the server
 

  
  const couponuse = "00";

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    
    formData.append('couponCode',couponCode);
    formData.append('couponCodeqty',couponCodeqty);
    formData.append('minimumordervalue',miniumordervalue);
    formData.append('couponuse',couponuse);
    formData.append('discount',discountvalue);
    formData.append('expiryDate', expiryDate);
    formData.append('status',"Active");
   

   
    try {
      const response = await fetch(`${config.API_BASE_URL}/addcoupon`, {
        
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Clear the form fields after submission
      setCouponcode('');
      setCouponcodeqty('');
      setMinimumordervalue('');
      setdiscountvalue('');
      setExpiryDate('');
     
      alert('Coupon Code added successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('Error adding Coupon'+error);
    }
  };

  return (
    <div className="product-container">
      <div className="add-product-container">
        <h1 className="text-center mb-4">Add Coupon Code</h1>
        <form onSubmit={handleSubmit}>
          
          <div className="form-group mb-3">
            <label htmlFor="description">Coupon Code</label>
            <input
                type="text"
                className="form-control mt-2"
                id="couponCode"
                placeholder="Enter Coupon Code"
                value={couponCode}
                onChange={(e) => setCouponcode(e.target.value)}
                required
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="description">Coupon QTY</label>
            <input
                type="number"
                className="form-control mt-2"
                id="couponCode"
                placeholder="Enter Coupon Code QTY"
                value={couponCodeqty}
                onChange={(e) => setCouponcodeqty(e.target.value)}
                required
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="description">Minimum Order Value</label>
            <input
                type="number"
                className="form-control mt-2"
                id="minordervalue"
                placeholder="Enter Coupon Code QTY"
                value={miniumordervalue}
                onChange={(e) => setMinimumordervalue(e.target.value)}
                required
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="description">Discount Applicable</label>
            <input
                type="number"
                className="form-control mt-2"
                id="discountvalue"
                placeholder="Enter Discount without using %"
                value={discountvalue}
                onChange={(e) => setdiscountvalue(e.target.value)}
                required
            />
          </div>
         
        
          <div className="form-group mb-3">
            <label htmlFor="expiryDate">Coupon Expiry Date</label>
            <input
              type="date"
              className="form-control mt-2"
              id="expiryDate"
              placeholder="Select Expiry Date"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              required
            />
          </div>
          


          
         
       
         

         

         
          <button type="submit" className="btn btn-primary btn-block mt-3">
            Save Coupon
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddCouponCode;
