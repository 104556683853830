import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Home.css'; // Import custom CSS

import config from '../config';

import logo from '../logo515.png';

const Home = () => {
  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="d-flex justify-content-center align-items-center flex-grow-1">
        <img src={logo} alt="Logo" />
      </div>
     
    </div>
  );
};

export default Home;
