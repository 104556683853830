import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './Viewcategory.css'; // Import the CSS file

import config from '../config';

const ViewCategory = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchcategories`);
        const data = await response.json();
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleDelete = async (category_name) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/deletecategories/${category_name}`, {
        method: 'DELETE',
      });

      if (response.ok) {
        setCategories(categories.filter(category => category_name !== category_name));
      } else {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="view-category-container">
      <h1 className="text-center">View Categories</h1>
      <ul className="category-list">
        {categories.map(category => (
          <li key={category.category_name} className="category-item">
            <span>{category.category_name}</span>
            <button
              className="btn-delete"
              onClick={() => handleDelete(category.category_name)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ViewCategory;
