import React, { useState, useEffect } from 'react';
import './Addproduct.css';

import config from '../config';

function AddProduct() {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [information, setInformation] = useState('');
  const [price, setPrice] = useState('');
  const [overprice, setOverprice] = useState('');
  const [fbLink, setFbLink] = useState('');
  const [instaLink, setInstaLink] = useState('');
  const [youtubeLink, setYoutubeLink] = useState('');
  const [lastProductSell, setLastProductSell] = useState('');
  const [features, setFeatures] = useState([]);
  const [newFeature, setNewFeature] = useState('');
  const [stockStatus, setStockStatus] = useState('available');
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  // Fetch categories from the server
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchcategories`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
      
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleFeatureAdd = () => {
    if (newFeature.trim()) {
      setFeatures((prevFeatures) => [...prevFeatures, newFeature.trim()]);
      setNewFeature(''); // Clear the input field after adding
    }
  };

  const handleFeatureRemove = (index) => {
    setFeatures((prevFeatures) => prevFeatures.filter((_, i) => i !== index));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages((prevImages) => [...prevImages, ...files]);

    // Generate image previews
    const newPreviews = files.map(file => URL.createObjectURL(file));
    setImagePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const handleImageRemove = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    setImagePreviews((prevPreviews) => {
      const updatedPreviews = prevPreviews.filter((_, i) => i !== index);
      URL.revokeObjectURL(prevPreviews[index]);
      return updatedPreviews;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('category', selectedCategory);
    formData.append('productName', productName);
    formData.append('description', description);
    formData.append('information', information);
    formData.append('price', price);
    formData.append('overprice', overprice);
    formData.append('fbLink', fbLink);
    formData.append('instaLink', instaLink);
    formData.append('youtubeLink', youtubeLink);
    formData.append('lastProductSell', lastProductSell);
    formData.append('stockStatus', stockStatus);
    features.forEach((feature) => 
    {
      formData.append(`features`, feature);
    });
    images.forEach((image) => {
      formData.append(`images`, image);
    });

    console.log('FormData:', formData);
    try {
      const response = await fetch(`${config.API_BASE_URL}/addproduct`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Clear the form fields after submission
      setSelectedCategory('');
      setProductName('');
      setDescription('');
      setInformation('');
      setPrice('');
      setOverprice('');
      setFbLink('');
      setInstaLink('');
      setYoutubeLink('');
      setLastProductSell('');
      setImages([]);
      setImagePreviews([]);
      setFeatures([]);
      alert('Product added successfully!');
    } catch (error) {
      console.error('Error:', error);
      alert('Error adding product'+error);
    }
  };

  return (
    <div className="product-container">
      <div className="add-product-container">
        <h1 className="text-center mb-4">Add Product</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              className="form-control mt-2"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              required
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.category_name} value={category.category_name}>
                  {category.category_name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group mb-3">
            <label htmlFor="productName">Product Name</label>
            <input
              type="text"
              className="form-control mt-2"
              id="productName"
              placeholder="Enter product name"
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="description">Description</label>
            <textarea
              className="form-control mt-2"
              id="description"
              placeholder="Enter product description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="information">Information</label>
            <textarea
              className="form-control mt-2"
              id="information"
              placeholder="Enter additional information"
              value={information}
              onChange={(e) => setInformation(e.target.value)}
              required
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="features">Features</label>
            <div className="input-group mt-2">
                <input
                type="text"
                className="form-control"
                id="features"
                placeholder="Enter a feature"
                value={newFeature}
                onChange={(e) => setNewFeature(e.target.value)}
                />
                <button
                type="button"
                className="btn btn-primary"
                onClick={handleFeatureAdd}
                >
                Add Feature
                </button>
            </div>
            {features.length > 0 && (
                <ul className="list-group mt-3">
                {features.map((feature, index) => (
                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    {feature}
                    <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => handleFeatureRemove(index)}
                    >
                        Remove
                    </button>
                    </li>
                ))}
                </ul>
            )}
        </div>
    


          <div className="form-group mb-3">
            <label htmlFor="price">Price</label>
            <input
              type="number"
              className="form-control mt-2"
              id="price"
              placeholder="Enter price"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="overprice">Overprice</label>
            <input
              type="number"
              className="form-control mt-2"
              id="overprice"
              placeholder="Enter overprice"
              value={overprice}
              onChange={(e) => setOverprice(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="fbLink">Facebook Link</label>
            <input
              type="url"
              className="form-control mt-2"
              id="fbLink"
              placeholder="Enter Facebook link"
              value={fbLink}
              onChange={(e) => setFbLink(e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="instaLink">Instagram Link</label>
            <input
              type="url"
              className="form-control mt-2"
              id="instaLink"
              placeholder="Enter Instagram link"
              value={instaLink}
              onChange={(e) => setInstaLink(e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="youtubeLink">YouTube Link</label>
            <input
              type="url"
              className="form-control mt-2"
              id="youtubeLink"
              placeholder="Enter YouTube link"
              value={youtubeLink}
              onChange={(e) => setYoutubeLink(e.target.value)}
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="lastProductSell">Last Product Sale Count</label>
            <input
              type="text"
              className="form-control mt-2"
              id="lastProductSell"
              value={lastProductSell}
              onChange={(e) => setLastProductSell(e.target.value)}
            />
          </div>

          <div className="form-group mb-3">
            <label htmlFor="stockStatus">Stock Status</label>
            <select
                id="stockStatus"
                className="form-control mt-2"
                value={stockStatus}
                onChange={(e) => setStockStatus(e.target.value)}
                required
            >
                <option value="available">Available</option>
                <option value="outofstock">Out of Stock</option>
            </select>
          </div>

          <div className="form-group mb-3">
            <label htmlFor="images">Upload Images</label>
            <input
              type="file"
              className="form-control mt-2"
              id="images"
              multiple
              onChange={handleImageChange}
            />
          </div>
          {imagePreviews.length > 0 && (
            <div className="image-previews mt-3">
              {imagePreviews.map((preview, index) => (
                <div key={index} className="image-preview-container">
                  <img
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className="img-thumbnail"
                  />
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => handleImageRemove(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}


          <button type="submit" className="btn btn-primary btn-block mt-3">
            Save Product
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddProduct;
