import React, { useState } from 'react';
import './AddTestimonial.css';
import config from '../config';

function AddTestimonials() {
    const [testimonialMessage, setTestimonialMessage] = useState('');
    const [profileImage, setProfileImage] = useState(null);
    const [profileImagePreview, setProfileImagePreview] = useState('');
    const [testimonialImage, setTestimonialImage] = useState(null);
    const [testimonialImagePreview, setTestimonialImagePreview] = useState('');
    const [name, setName] = useState('');
    const [occupation, setOccupation] = useState('');
    const [videoLink, setVideoLink] = useState('No');
    const [isVideo, setIsVideo] = useState(false);

    const handleProfileImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProfileImage(file);
            setProfileImagePreview(URL.createObjectURL(file));
        }
    };

    const handleTestimonialImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setTestimonialImage(file);
            setTestimonialImagePreview(URL.createObjectURL(file));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Ensure only one of video link or testimonial image is set
        if (isVideo && !videoLink) {
            alert('Please enter a YouTube video link.');
            return;
        }

        if (!isVideo && !testimonialImage) {
            alert('Please upload a testimonial image.');
            return;
        }

        const formData = new FormData();
        formData.append('testimonialMessage', testimonialMessage);
        formData.append('profileImage', profileImage);
        formData.append('name', name);
        formData.append('occupation', occupation);
        formData.append('videoLink', videoLink);
        formData.append('testimonialImage', testimonialImage);

        // Only append video link if video is selected
      
        try {
            const response = await fetch(`${config.API_BASE_URL}/addtestimonials`, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) throw new Error('Network response was not ok');
            alert('Testimonial added successfully!');

            // Clear form fields after submission
            setTestimonialMessage('');
            setProfileImage('');
            setProfileImagePreview('');
            setTestimonialImage(null);
            setTestimonialImagePreview('');
            setName('');
            setOccupation('');
            setVideoLink('');
            setIsVideo(false);
        } catch (error) {
            console.error('Error:', error);
            alert('Error adding testimonial: ' + error);
        }
    };


    return (
        <div className="form-container">
            <h1 className="form-title">Add Testimonial</h1>
            <form className="testimonial-form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="testimonialMessage">Testimonial Message</label>
                    <textarea
                        className="form-control"
                        id="testimonialMessage"
                        placeholder="Enter testimonial message"
                        value={testimonialMessage}
                        onChange={(e) => setTestimonialMessage(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="profileImage">Profile Image</label>
                    <input
                        type="file"
                        className="form-control"
                        id="profileImage"
                        onChange={handleProfileImageChange}
                    />
                    {profileImagePreview && (
                        <img src={profileImagePreview} alt="Profile Preview" className="img-thumbnail" />
                    )}
                </div>

                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter your name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="occupation">Occupation</label>
                    <input
                        type="text"
                        className="form-control"
                        id="occupation"
                        placeholder="Enter your occupation"
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                        required
                    />
                </div>

                <div className="form-group">
                    <label>
                        <input
                            type="radio"
                            checked={isVideo}
                            onChange={() => setIsVideo(true)}
                        />
                        YouTube Video
                    </label>
                    <label>
                        <input
                            type="radio"
                            checked={!isVideo}
                            onChange={() => setIsVideo(false)}
                        />
                        Testimonial Image
                    </label>
                </div>

                {isVideo ? (
                    <div className="form-group">
                        <label htmlFor="videoLink">YouTube Video Link</label>
                        <input
                            type="url"
                            className="form-control"
                            id="videoLink"
                            placeholder="Enter YouTube video link"
                            value={videoLink}
                            onChange={(e) => setVideoLink(e.target.value)}
                        />
                    </div>
                ) : (
                    <div className="form-group">
                        <label htmlFor="testimonialImage">Upload Testimonial Image</label>
                        <input
                            type="file"
                            className="form-control"
                            id="testimonialImage"
                            onChange={handleTestimonialImageChange}
                        />
                        {testimonialImagePreview && (
                            <img src={testimonialImagePreview} alt="Testimonial Preview" className="img-thumbnail" />
                        )}
                    </div>
                )}

                <button type="submit" className="btn btn-primary btn-block">
                    Save Testimonial
                </button>
            </form>
        </div>
    );
}

export default AddTestimonials;
