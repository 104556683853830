import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './OrderDetails.css'; // Import custom CSS (if needed)

import config from '../config';

const OrderDetails = () => {
  const { orderId } = useParams(); // Get the order ID from the URL
  const [order, setOrder] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null); // New state for orderbook details
  const [loading, setLoading] = useState(false);

  // Define fetchOrderDetails function
  const fetchOrderDetails = async () => {
    console.log("Fetching order details...");
    try {
      const response = await fetch(`${config.API_BASE_URL}/fetchorderview/${orderId}`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setOrder(data);
      console.log("Fetched order:", data);

      // Fetch additional user details based on useremail and usermobile
      if (data.useremail && data.usermobile) {
        fetchUserDetails(data.useremail, data.usermobile);
      }
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  };

  // Fetch additional order details from orderbook table
  const fetchOrderDetailsFromOrderbook = async () => {
    console.log("Fetching order details from orderbook...");
    try {
      const response = await fetch(`${config.API_BASE_URL}/orderbookdetails/${orderId}`);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setOrderDetails(data);
      console.log("Fetched orderbook details:", data);
    } catch (error) {
      console.error('Error fetching orderbook details:', error);
    }
  };

  // Fetch user details based on email and mobile
  const fetchUserDetails = async (email, mobile) => {
    setLoading(true);
    try {
      const response = await fetch(`${config.API_BASE_URL}/finduser/${email}/${mobile}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setUserDetails(data); // Store user data
      console.log("User data fetched:", data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchOrderDetails(); // Fetch order details on component mount
    fetchOrderDetailsFromOrderbook(); // Fetch orderbook details on component mount
  }, [orderId]);

  const handleAction = async (action) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/update-order-status`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action, orderId }), // Include both action and orderId
      });
  
      if (response.ok) {
        alert(`Order has been ${action}`);
        // Refetch the order details to reflect the updated status
        fetchOrderDetails();
        fetchOrderDetailsFromOrderbook();
      } else {
        console.error('Error updating order:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleAction1 = async (action) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/update-order-dispatch`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action, orderId }), // Include both action and orderId
      });
  
      if (response.ok) {
        alert(`Order has been ${action}`);
        // Refetch the order details to reflect the updated status
        fetchOrderDetails();
        fetchOrderDetailsFromOrderbook();
      } else {
        console.error('Error updating order:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  const handleAction2 = async (action) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/update-order-deliver`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ action, orderId }), // Include both action and orderId
      });
  
      if (response.ok) {
        alert(`Order has been ${action}`);
        // Refetch the order details to reflect the updated status
        fetchOrderDetails();
        fetchOrderDetailsFromOrderbook();
      } else {
        console.error('Error updating order:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  if (!order || !orderDetails) return <div>Loading...</div>;

  // Extract fields from order and orderDetails
  const products = order.products || [];
  const discount = orderDetails.discount || 0;
  const payableAmount = orderDetails.payableamount || 0;
  const paymentType = orderDetails.paymenttype || 'N/A';
  const captureId1 = orderDetails.captureid1 || 'N/A';
  const captureId2 = orderDetails.captureid2 || 'N/A';
  const captureId3 = orderDetails.captureid3 || 'N/A';
  const orderStatus = orderDetails.orderstatus || 'Pending';
  const orderDispatch = orderDetails.orderdispatch || 'N/A';
  const orderDeliver = orderDetails.orderdeliver || 'N/A';

  // Calculate total amount dynamically from products
  const calculateTotalAmount = () => {
    return products.reduce((total, product) => {
      return total + parseFloat(product.amount || 0); // Handle case where product.amount might be undefined or null
    }, 0);
  };

  const calculateTotalQuantity = () => {
    return products.reduce((total, product) => {
      return total +  parseInt(product.qty || 0); // Handle case where product.qty might be undefined or null
    }, 0);
  };



  // Format totalAmount, discount, and payableAmount to be shown in Rupees
  const formatCurrency = (value) => new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  }).format(value);

  const formattedTotalAmount = formatCurrency(calculateTotalAmount());
  const formattedDiscount = formatCurrency(discount);
  const formattedPayableAmount = formatCurrency(payableAmount);

  // User details
  const user = userDetails || {};

  return (
    <div className="container mt-5">
      <h1>Order Summary</h1>
      <div>
        {loading ? (
          <div>Loading user details...</div>
        ) : (
          <div className="user-details mt-4">
            <h3>Customer Details</h3>
            <div className="row">
              <div className="col-md-4 user-detail-item">
                <p><strong>First Name:</strong></p>
                <p>{user.Firstname}</p>
              </div>
              <div className="col-md-4 user-detail-item">
                <p><strong>Last Name:</strong></p>
                <p>{user.Lastname}</p>
              </div>
              <div className="col-md-4 user-detail-item">
                <p><strong>Mobile No:</strong></p>
                <p>{user.Mobileno}</p>
              </div>
              <div className="col-md-4 user-detail-item">
                <p><strong>Email:</strong></p>
                <p>{user.Email}</p>
              </div>
              <div className="col-md-4 user-detail-item">
                <p><strong>Address Line 1:</strong></p>
                <p>{user.AddressLine1}</p>
              </div>
              <div className="col-md-4 user-detail-item">
                <p><strong>Address Line 2:</strong></p>
                <p>{user.AddressLine2}</p>
              </div>
              <div className="col-md-4 user-detail-item">
                <p><strong>City:</strong></p>
                <p>{user.City}</p>
              </div>
              <div className="col-md-4 user-detail-item">
                <p><strong>State:</strong></p>
                <p>{user.State}</p>
              </div>
              <div className="col-md-4 user-detail-item">
                <p><strong>Zip Code:</strong></p>
                <p>{user.ZipCode}</p>
              </div>
            </div>
          </div>
        )}
        <br />
        <h3>Order Details</h3>
        <table className="table table-striped mt-4">
          <thead>
            <tr>
              <th>Srno</th>
              <th>Product Name</th>
              <th>Product Image</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 ? (
              products.map((product, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{product.productName}</td>
                  <td>
                    {product.images && JSON.parse(product.images)[0] ? (
                      <img
                        src={`${config.API_BASE_URL}/images/${JSON.parse(product.images)[0]}`} // Construct URL for the first image
                        alt="Product Image 0"
                        style={{ maxWidth: '100px', height: 'auto', marginRight: '5px' }} // Adjust styles as needed
                      />
                    ) : (
                      <span>No image available</span> // Optional: Fallback if there are no images
                    )}
                  </td>
                  <td>{product.qty}</td>
                  <td>₹{product.price}</td> {/* Updated to show in Rupees */}
                  <td>₹{product.amount}</td> {/* Updated to show in Rupees */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">No products found</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="total-amount mt-4 text-center">
          <h5 className="text-muted">Total Quantity</h5>
          <p className="h4 font-weight-bold">{calculateTotalQuantity()}</p>
          <h5 className="text-muted">Total Amount</h5>
          <p className="h4 font-weight-bold">{formattedTotalAmount}</p>
          <h5 className="text-muted">Discount</h5>
          <p className="h4 font-weight-bold">{formattedDiscount}</p>
          <h5 className="text-muted">Payable Amount</h5>
          <p className="h4 font-weight-bold">{formattedPayableAmount}</p>
        </div>
        <div className="additional-info mt-4">
                  <h3 className="font-weight-bold">Additional Information</h3>
                  <dl className="row">
                    <dt className="col-sm-4">Payment Type:</dt>
                    <dd className="col-sm-8">{paymentType}</dd>

                    <dt className="col-sm-4">Capture ID 1:</dt>
                    <dd className="col-sm-8">{captureId1}</dd>

                    <dt className="col-sm-4">Capture ID 2:</dt>
                    <dd className="col-sm-8">{captureId2}</dd>

                    <dt className="col-sm-4">Capture ID 3:</dt>
                    <dd className="col-sm-8">{captureId3}</dd>

                    <dt className="col-sm-4">Order Status:</dt>
                    <dd className="col-sm-8">{orderStatus}</dd>

                    <dt className="col-sm-4">Order Dispatch:</dt>
                    <dd className="col-sm-8">{orderDispatch}</dd>

                    <dt className="col-sm-4">Order Deliver:</dt>
                    <dd className="col-sm-8">{orderDeliver}</dd>
                  </dl>
            </div>

        <div className="action-buttons mt-4">
          <button className="btn btn-success" onClick={() => handleAction('Accept')}>Order Accept</button>
          <button className="btn btn-warning" onClick={() => handleAction1('Dispatch')}>Order Dispatch</button>
          <button className="btn btn-info" onClick={() => handleAction2('Deliver')}>Order Deliver</button>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
