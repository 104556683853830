import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Viewproduct.css'; // Import custom CSS
import { FaEye } from 'react-icons/fa'; // Import eye icon from react-icons

import config from '../config';

const Neworder = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [statusFilter, setStatusFilter] = useState('');
  const [dispatchFilter, setDispatchFilter] = useState('');
  const [deliverFilter, setDeliverFilter] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchorders`); // Updated URL
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setOrders(data);
        console.log("Fetched orders:", data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    // Apply filters to orders
    const applyFilters = () => {
      let result = orders;

      if (statusFilter) {
        result = result.filter(order => order.orderstatus === statusFilter);
      }

      if (dispatchFilter) {
        result = result.filter(order => order.orderdispatch === dispatchFilter);
      }

      if (deliverFilter) {
        result = result.filter(order => order.orderdeliver === deliverFilter);
      }

      setFilteredOrders(result);
    };

    applyFilters();
  }, [orders, statusFilter, dispatchFilter, deliverFilter]);

  const handleViewOrderClick = (orderId) => {
    navigate(`/orderdetails/${orderId}`); // Navigate to order details page
  };

  return (
    <div className="container mt-5">
      <div className="heading-container">
        <h1>View Orders</h1>
      </div>

      <div className="filter-container mb-4">
        <div className="row">
          <div className="col-md-4">
            <label htmlFor="statusFilter">Order Status:</label>
            <select
              id="statusFilter"
              className="form-control"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="pending">pending</option>
              <option value="Accept">Accept</option>
            
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="dispatchFilter">Order Dispatch:</label>
            <select
              id="dispatchFilter"
              className="form-control"
              value={dispatchFilter}
              onChange={(e) => setDispatchFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="pending">pending</option>
              <option value="dispatched">dispatched</option>
            </select>
          </div>
          <div className="col-md-4">
            <label htmlFor="deliverFilter">Order Deliver:</label>
            <select
              id="deliverFilter"
              className="form-control"
              value={deliverFilter}
              onChange={(e) => setDeliverFilter(e.target.value)}
            >
              <option value="">All</option>
              <option value="pending">pending</option>
              <option value="Deliver">Deliver</option>
            </select>
          </div>
        </div>
      </div>

      <div className="table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Order No</th>
              <th>User Email</th>
              <th>User Mobile</th>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>Address Line 1</th>
              <th>Address Line 2</th>
              <th>City</th>
              <th>State</th>
              <th>Zip Code</th>
              <th>Amount</th>
              <th>Discount</th>
              <th>Payable Amount</th>
              <th>Payment Type</th>
              <th>Capture ID 1</th>
              <th>Capture ID 2</th>
              <th>Capture ID 3</th>
              <th>Order Status</th>
              <th>Order Dispatch</th>
              <th>Order Deliver</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredOrders.length > 0 ? (
              filteredOrders.map(order => (
                <tr key={order.id}>
                  <td>{order.id}</td>
                  <td>{order.useremail}</td>
                  <td>{order.usermobile}</td>
                  <td>{order.Firstname}</td>
                  <td>{order.Lastname}</td>
                  <td>{order.AddressLine1}</td>
                  <td>{order.AddressLine2}</td>
                  <td>{order.City}</td>
                  <td>{order.State}</td>
                  <td>{order.ZipCode}</td>
                  <td>{order.amount}</td>
                  <td>{order.discount}</td>
                  <td>{order.payableamount}</td>
                  <td>{order.paymenttype}</td>
                  <td>{order.captureid1}</td>
                  <td>{order.captureid2}</td>
                  <td>{order.captureid3}</td>
                  <td>{order.orderstatus}</td>
                  <td>{order.orderdispatch}</td>
                  <td>{order.orderdeliver}</td>
                  <td>
                    <button
                      className="btn btn-info"
                      onClick={() => handleViewOrderClick(order.id)}
                    >
                      <FaEye /> View
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="20">No orders found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Neworder;
