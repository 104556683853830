import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Viewproduct.css'; // Import custom CSS

import config from '../config';

const ViewBlogs = () => {
  const [blogs, setblogs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchblogs`);
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setblogs(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  

  

  const handleDeleteClick = async (productId) => {
    try {
      const response = await fetch(`${config.API_BASE_URL}/deleteblogs/${productId}`, {
        method: 'DELETE',
      });

      if (response.ok) 
      {
       alert("Product has been deleted");
      } 
      else 
      {
        console.error('Error deleting category:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <div className="container mt-5">
      <div className="heading-container">
        <h1>View blogs</h1>
      </div>
      <div className="table-container">
        <table className="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Blogs Content</th>
             
              <th>Images</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map(blogs => (
              <tr key={blogs.id}>
                <td>{blogs.id}</td>
                <td>{blogs.blogcontent}</td>
               
                
                <td>
                  <ul className="list-unstyled">
                    {JSON.parse(blogs.images).map((image, index) => (
                      <li key={index}>
                        <img
                          src={`${config.API_BASE_URL}/blogimages/${image}`} // Construct URL for each image
                          alt={`Product Image ${index}`}
                          style={{ maxWidth: '100px', height: 'auto' }} // Adjust styles as needed
                        />
                      </li>
                    ))}
                  </ul>
                </td>
                <td>
                 
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDeleteClick(blogs.id)}
                  >
                    Delete
                  </button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewBlogs;
