import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Addproduct.css';

import config from '../config';

function UpdateProduct() {
  const navigate = useNavigate();
  const { productId } = useParams(); 
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [product, setProduct] = useState(null);
  const [features, setFeatures] = useState([]);
  const [featureInput, setFeatureInput] = useState('');
  const [updatedProduct, setUpdatedProduct] = useState({
    name: '',
    description: '',
    information: '',
    price: '',
    overprice: '',
    fbLink: '',
    instaLink: '',
    youtubeLink: '',
    lastProductSell: '',
    stockStatus: 'available',
  });
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/fetchcategories`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
      
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/product/${productId}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setProduct(data);

        // Set product details
        setUpdatedProduct({
          name: data.productName,
          description: data.description,
          information: data.information,
          price: data.price,
          overprice: data.overprice,
          fbLink: data.fbLink,
          instaLink: data.instaLink,
          youtubeLink: data.youtubeLink,
          lastProductSell: data.lastProductSell,
          stockStatus: data.stockStatus,
        });

        // Set features
        setFeatures(JSON.parse(data.features));
        console.log("Mohit "+features)

        // Load existing images and previews
        const existingPreviews = JSON.parse(product.images);
       
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    fetchProduct();
  }, [productId]);

  const handleFeatureAdd = () => {
    if (featureInput.trim()) {
      const newFeatures = featureInput
        .split(',')
        .map(feature => feature.trim())
        .filter(feature => feature.length > 0);

      setFeatures(prevFeatures => [...new Set([...prevFeatures, ...newFeatures])]);
      setFeatureInput('');
    }
  };

  const handleFeatureRemove = (index) => {
    setFeatures(prevFeatures => prevFeatures.filter((_, i) => i !== index));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(prevImages => [...prevImages, ...files]);

    // Generate image previews
    const newPreviews = files.map(file => URL.createObjectURL(file));
    setImagePreviews(prevPreviews => [...prevPreviews, ...newPreviews]);
  };

  const handleImageRemove = (index) => {
    setImages(prevImages => prevImages.filter((_, i) => i !== index));
    setImagePreviews(prevPreviews => {
      const updatedPreviews = prevPreviews.filter((_, i) => i !== index);
      URL.revokeObjectURL(prevPreviews[index]);
      return updatedPreviews;
    });
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUpdatedProduct(prevProduct => ({ ...prevProduct, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('productId', productId);
    formData.append('category', selectedCategory);
    formData.append('productName', updatedProduct.name);
    formData.append('description', updatedProduct.description);
    formData.append('information', updatedProduct.information);
    formData.append('price', updatedProduct.price);
    formData.append('overprice', updatedProduct.overprice);
    formData.append('fbLink', updatedProduct.fbLink);
    formData.append('instaLink', updatedProduct.instaLink);
    formData.append('youtubeLink', updatedProduct.youtubeLink);
    formData.append('lastProductSell', updatedProduct.lastProductSell);
    formData.append('stockStatus', updatedProduct.stockStatus);
    features.forEach((feature) => 
      {
        formData.append(`features`, feature);
      }); // Ensure this is an array of strings
    images.forEach(image => {
      formData.append('images', image);
    });

    try {
      const response = await fetch(`${config.API_BASE_URL}/updateproduct`, {
          method: 'PUT',
          body: formData,
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const result = await response.json();
      alert('Product updated successfully!');
      navigate(`/view-product`);
      console.log(result);
  } catch (error) {
      console.error('Error updating product:', error);
      alert('Error updating product: ' + error.message);
  }
  };

  return (
    <div className="product-container">
      <div className="add-product-container">
      <h1 className="text-center mb-4">Update Product</h1>
      <div className="form-group mb-3">
            <label htmlFor="category">Category</label>
            <select
              id="category"
              className="form-control mt-2"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              required
            >
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.category_name} value={category.category_name}>
                  {category.category_name}
                </option>
              ))}
            </select>
          </div>
      {product && (
        <form onSubmit={handleSubmit}>
          <div className="form-group mb-3">
            <label htmlFor="name">Product Name</label>
            <input
              type="text"
              className="form-control mt-2"
              id="name"
              placeholder="Enter product name"
              value={updatedProduct.name}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="description">Description</label>
            <textarea
              className="form-control mt-2"
              id="description"
              placeholder="Enter product description"
              value={updatedProduct.description}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="information">Information</label>
            <textarea
              className="form-control mt-2"
              id="information"
              placeholder="Enter additional information"
              value={updatedProduct.information}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="features">Features</label>
            <div className="input-group mt-2">
              <input
                type="text"
                className="form-control"
                id="featureInput"
                placeholder="Enter features, separated by commas"
                value={featureInput}
                onChange={(e) => setFeatureInput(e.target.value)}
              />
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleFeatureAdd}
              >
                Add Features
              </button>
            </div>
            {features.length > 0 && (
              <ul className="list-group mt-3">
                {features.map((feature, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    {feature}
                    <button
                      type="button"
                      className="btn btn-danger btn-sm"
                      onClick={() => handleFeatureRemove(index)}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="form-group mb-3">
            <label htmlFor="price">Price</label>
            <input
              type="number"
              className="form-control mt-2"
              id="price"
              placeholder="Enter price"
              value={updatedProduct.price}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="overprice">Overprice</label>
            <input
              type="number"
              className="form-control mt-2"
              id="overprice"
              placeholder="Enter overprice"
              value={updatedProduct.overprice}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="fbLink">Facebook Link</label>
            <input
              type="url"
              className="form-control mt-2"
              id="fbLink"
              placeholder="Enter Facebook link"
              value={updatedProduct.fbLink}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="instaLink">Instagram Link</label>
            <input
              type="url"
              className="form-control mt-2"
              id="instaLink"
              placeholder="Enter Instagram link"
              value={updatedProduct.instaLink}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="youtubeLink">YouTube Link</label>
            <input
              type="url"
              className="form-control mt-2"
              id="youtubeLink"
              placeholder="Enter YouTube link"
              value={updatedProduct.youtubeLink}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="lastProductSell">Last Product Sold</label>
            <input
              type="text"
              className="form-control mt-2"
              id="lastProductSell"
              value={updatedProduct.lastProductSell}
             onChange={handleInputChange}
            />
          </div>
          <div className="form-group mb-3">
            <label htmlFor="stockStatus">Stock Status</label>
            <select
              className="form-control mt-2"
              id="stockStatus"
              value={updatedProduct.stockStatus}
              onChange={handleInputChange}
            >
              <option value="available">Available</option>
              <option value="out-of-stock">Out of Stock</option>
            </select>
          </div>
          <div className="form-group mb-3">
            <label htmlFor="images">Product Images</label>
            <input
              type="file"
              className="form-control mt-2"
              id="images"
              multiple
              onChange={handleImageChange}
            />
          {imagePreviews.length > 0 && (
            <div className="image-previews mt-3">
              {imagePreviews.map((preview, index) => (
                <div key={index} className="image-preview-container">
                  <img
                    src={preview}
                    alt={`Preview ${index + 1}`}
                    className="img-thumbnail"
                  />
                  <button
                    type="button"
                    className="btn btn-danger btn-sm"
                    onClick={() => handleImageRemove(index)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          )}
          </div>
          <button type="submit" className="btn btn-primary">Update Product</button>
        </form>
      )}
    </div>
    </div>
  );
}

export default UpdateProduct;
