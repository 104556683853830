import React from 'react';
import './Addcategory.css';
import { useState } from 'react';

import config from '../config';

function AddCategory() {

    const [categoryName, setCategoryName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        
        try {
          const response = await fetch(`${config.API_BASE_URL}/addcategories`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: categoryName }),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          // Clear the input field after submission
          setCategoryName('');
          alert('Category added successfully!');
        } catch (error) {
          console.error('Error:', error);
          alert('Error adding category');
        }
      };

  return (
    <div className="app-container">
      <div className="container">
        <h1 className="text-center mb-4">Add Category</h1>
        <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="exampleInput">Category Name</label>
          <input
            type="text"
            className="form-control mt-2"
            id="categoryName"
              placeholder="Enter category name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
              required
          />
        </div>
        <button type="submit" className="btn btn-primary btn-block mt-3">
          Save Category
        </button>
        </form>
      </div>
    </div>
  );
}

export default AddCategory;
